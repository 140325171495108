@font-face {
  font-family: 'GoodPro-Black';
  src: url('./assets/GoodPro-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Riposte-Regular';
  src: url('./assets/Riposte-Regular.otf') format('opentype');
}

body {
  margin: 0;
  overflow: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background: #231F20;
}

/* .fill-window {

  height: 100%;
  left: 0;
  width: 100%;
} */

.nav-button {
  cursor: pointer;
  display: inline-block;
  padding: 0.5em 3em;
  margin: 15px 1.3em 4.3em 3px;
  box-sizing: border-box;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'GoodPro-Black';
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  transition: all 0.15s;
  border: none;
  background: none;
}

.back, .delete {
  color: black;
}

.edit {
  color: black;
  border: 2px solid;
  margin: 15px 1.3em 1.3em 3px;
}

.print {
  color: black;
  position: fixed;
  bottom: 0px;
  left: 10px;
  border: 2px solid;
  margin: 15px 1.3em 1.3em 3px;
}

.copy {
  border: 2px solid;
  color: black;
  position: fixed;
  bottom: 0px;
  left: 166px;
  margin: 15px 1.3em 1.3em 3px;
}

.nav-button:hover {
  color: #686767;
  border-color: #686767;
}

.nav-button:active {
  color: #BBBBBB;
  border-color: #BBBBBB;
}

@media all and (max-width:30em) {
  .nav-button {
    display: block;
    margin: 0;
  }
}

@media all and (max-width:30em) {
  .print {
    margin: 15px 1.3em 1.3em 3px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-text {
  font-family: 'GoodPro-Black';
}

.link-text {
  font-family: 'Riposte-Regular';
}

p {
  font-size: 14px;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

img.rounded {
  object-fit: cover;
  border-radius: 50%;
  height: 250px;
  width: 250px;
}

.employee-info {
  text-align: center;
}

.clear-margin {
  margin: 15px;
}

.space-top {
  margin-top: 10px;
}

.space-right {
  margin-right: 10px;
}

.icon-left {
  margin-right: 5px;
}

.icon-right {
  margin-left: 5px;
}

.labels {
  word-spacing: 5px;
  line-height: 2;
}

.label-keyword {
  display: inline-block;
  background: #FBCCCD;
  color: black;
  font-size: 0.9em;
  padding: 0px 5px;
  border: 1px solid #FBCCCD;
  border-radius: .45em;
  margin: 10px 10px 10px 10px;
}

.link-disguise {
  color: inherit;
}

.link-disguise:hover {
  color: inherit;
}

.text-bolder {
  font-weight: bold;
}

.profile-card {
  display: block;
  width: 100%;
}

.logo {
  display: table-cell;
  float: right;
}

.logo img {
  height: 61px;
}

.profile-pic {
  display: table-cell;
  vertical-align: top;
  padding: 1rem 4rem 0 0;
}

.profile-pic img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.contact-details {
  display: table-cell;
}

.contact-details .detail {
  display: inline-block;
  line-height: 2;
  margin-right: 2rem;
}

.contact-details .detail .icon {
  padding-right: 7px;
  color: #888;
}

.contact-details .detail .info {
  font-size: 0.8em;
  font-family: 'Riposte-Regular';
}

.social-links {
  line-height: 2.5;
}

.social-link {
  display: block;
}

.social-link span {
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
}

.social-link .text {
  font-family: 'Riposte-Regular';
  font-weight: lighter;
}

.social-link:hover,
.social-link:focus {
  text-decoration: none;
}

.social-link .fa {
  text-align: center;
  width: 2em;
}

.fa-github {
  color: #454545;
}

.fa-github:hover,
.fa-github:focus {
  text-decoration: none;
  color: #2b2b2b;
}

.fa-twitter {
  color: #33ccff;
}

.fa-twitter:hover,
.fa-twitter:focus {
  text-decoration: none;
  color: #00bfff;
}

.fa-facebook {
  color: #4b6daa;
}

.fa-facebook:hover,
.fa-facebook:focus {
  text-decoration: none;
  color: #3b5687;
}

.fa-instagram {
  color: #fb3958;
}

.fa-instagram:hover,
.fa-instagram:focus {
  text-decoration: none;
  color: #fb3958;
}

.background-card h4 {
  font-variant: small-caps;
  color: #777777;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 1em;
  margin-bottom: 1em;
  line-height: 1.2;
}

.background-card h4:not(:first-child) {
  margin-top: 2em;
}

.title {
  font-family: 'Goodpro-Black';
  letter-spacing: 0.1em;
}

p {
  font-family: 'Riposte-Regular';
}

.text-muted {
  font-family: 'Riposte-Regular';
  font-weight: lighter;
  font-size: 14px;
  color: #777777;
}

h4>.fa,
h4>.title {
  display: inline-block;
  vertical-align: baseline;
}

h4>.fa {
  width: 4rem;
  color: #231F20
}

.content {
  line-height: 1.5;
}

.card {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  padding: 2em 3.5em;
}

.card-nested {
  padding: 1.5rem 0 1.5rem 1.2rem;
  font-family: 'Riposte-Regular';
  list-style-type: none;
}

.card-wrapper {
  padding: 5px;
}

.enumeration:not(:last-child):after {
  content: ", ";
}

.enumeration:last-child:after {
  content: ".";
}

.card-skills {
  position: relative;
}

.skill-info {
  margin-left: 20px;
}

.skill-name {
  font-family: 'Riposte-Regular';
}

.skill-keywords {
  font-family: 'Riposte-Regular';
  font-weight: lighter;
  content: ", ";
}

#footer-icon {
  color: white;
  padding: 1rem 1rem;
  font-size: 30px;
}

/* LOADING SPINNER */

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader:after {
  background: #231F20;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 768px) {

  .skill {
    border-left: 9px solid #555555;
    list-style-type: none;
  }

  .skill.beginner {
    height: 50%;
    border-color: #f7e4e4;
  }

  .skill.intermediate {
    height: 70%;
    border-color: #fadfe0;
  }

  .skill.advanced {
    border-color: #f7d7d7;
  }

  .skill.master {
    border-color: #FBCCCD;
  }

  .quote {
    font-size: inherit;
    border-left: 9px solid rgb(199, 198, 198);
  }
}

@media (max-width: 768px) {
  .quote {
    font-size: inherit;
  }

  .profile-pic {
    display: table-cell;
    vertical-align: top;
    padding: 0 0 0 0;
  }
}

@media print {
  body {
    font-size: 10pt;
  }

  a[href]:after {
    content: none !important;
  }

  .pagebreak {
    page-break-before: always;
  }

  .background-card h4:not(:first-child) {
    margin-top: 0 !important;
  }

  .card {
    border: 0;
    padding: 0;
  }

  .container {
    max-width: 100%;
    width: 100%;
  }

  .contact-details .detail .icon {
    color: #888 !important;
  }

  .background-card h4 {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    margin-top: 1em !important;
  }

  h4>.fa {
    display: none !important;
  }

  .card-nested {
    padding: .5rem 0 .5rem 0;
  }

  .labels {
    display: inline;
  }

  .skill-info {
    margin-left: 0;
  }

  blockquote {
    font-size: 100%;
  }

}

@media (max-width: 992px) {
  .clear-margin-sm {
    margin-bottom: 0;
  }
}

@media only screen {
  .container {
    max-width: 50em;
  }
}

@media (max-width: 480px) {

  body {
    position: absolute;
  }

  ul {
    padding-left: 25px;
  }

  .background-details {
    display: block;
  }

  .background-details .icon {
    max-width: inherit;
    min-width: inherit;
    text-align: left;
  }

  .background-details .icon,
  .background-details .info {
    display: block;
    padding: 10px 0;
  }

  .background-details .title {
    display: none;
  }

  .card-nested {
    padding: 5px 0;
  }
}

@media only screen and (max-width: 768px) {

  .profile-card {
    display: block;
  }

  .contact-details,
  .profile-pic {
    display: block;
    text-align: center;
  }

  .card-nested {
    padding-left: 0;
  }

  .card {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    padding: 2em 1.5em;
  }

  .quote {
    font-size: inherit;
    margin-inline-start: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .contact-details .detail {
    display: block;
  }
}
